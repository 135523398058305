<template>
  <div class="terms">
    <div class="container">
      <h1>Termos e condições de uso do site.</h1>
      <section class="term">
        <h2><span>Condições de </span>Utilização</h2>
        <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
      </section>
      <section class="term">
        <h2><span>Regras de </span>Utilização</h2>
        <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
      </section>
      <section class="term">
        <h2><span>Nossas </span>Responsabilidades</h2>
        <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
      </section>
      <section class="term">
        <h2><span>Direitos de propriedade </span>Intelectual</h2>
        <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Terms'
}
</script>

<style lang="scss" scoped>
  .terms {
    padding: 0px 0 80px;

    h1 {
      font-weight: 400;
      font-size: 60px;
      line-height: 70px;
      font-weight: 400;
      margin: 144px 0 86px;
      max-width: 650px;
      color: var(--secundary-black);
    }

    .term {
      margin-bottom: 64px;
      h2 {
        font-size: var(--size-h2);
        line-height: 48px;
        color: var(--secundary-black);
        font-weight: 700;
        margin-bottom: 24px;

        span {
          display: block;
          font-weight: 300;
        }
      }

      p {
        font-weight: 300;
        line-height: 40px;
        font-size: var(--size-large);
        color: var(--atomic);
        margin-bottom: 16px;
        span {
          font-weight: 500;
        }
      }
    }

    .container {
      max-width: 1200px;
    }

    .about-main {
      h2 {
        font-size: var(--size-h2);
        line-height: 48px;
        color: var(--green-custom);
        font-weight: 300;
      }

      .about-boxes {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 32px;

        .about-box {
          background: var(--white-smoke);
          border-radius: 25px;
          padding: 32px;
          max-width: 416px;
          min-height: 566px;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;

          img {
            margin-bottom: auto;
          }

          h3 {
            color: var(--secundary-black);
            font-size: var(--size-h2);
            line-height: 36px;
            font-weight: 500;
            margin-bottom: 24px;
          }

          ul {
            list-style: none;
          }

          p,
          li {
            font-weight: 300;
            font-size: 20px;
            line-height: 40px;
            font-size: var(--size-large);
            color: var(--atomic);
            padding: 0 0;
            margin: 0 0;
          }
        }
      }
    }

    .about-second {
      img {
        width: 100%;
      }
    }

    .about-header {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 40px;

      p {
        color: var(--atomic);
        font-weight: 300;
        font-size: 20px;
        line-height: 35px;
        max-width: 640px;
        margin: 0 0 30px;
      }

      .about-image {
        position: relative;
        max-width: 640px;
        width: 100%;

        img {
          max-width: 650px;
          height: auto;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 80px 0 60px;

      h1 {
        font-size: 46px;
        line-height: 56px;
        margin: 0px 0 30px;
      }

      h2 {
        margin-bottom: 32px;
      }

      .about-header {
        flex-direction: column;
        .about-image {
          img {
            height: auto;
            width: 100%;
          }
        }
      }
    }

  }
</style>
